"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.THEME = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const client_1 = require("react-dom/client");
const functions_1 = require("./functions");
const types_1 = require("./types");
exports.THEME = (0, material_1.responsiveFontSizes)((0, material_1.createTheme)({
    palette: {
        mode: "light",
        background: {
            default: types_1.COLORS.bgcolor,
        },
    },
}));
function App() {
    const [username, setUsername] = (0, react_1.useState)("");
    const [password, setPassword] = (0, react_1.useState)("");
    const year = new Date().getFullYear();
    const login = (0, react_1.useCallback)(() => __awaiter(this, void 0, void 0, function* () {
        try {
            yield (0, functions_1.post)("/login/", { username, password }, "POST");
            window.location.href = "/";
        }
        catch (error) {
            console.error("Error logging in", error);
        }
    }), [username, password]);
    return ((0, jsx_runtime_1.jsxs)(material_1.ThemeProvider, { theme: exports.THEME, children: [(0, jsx_runtime_1.jsx)(material_1.CssBaseline, {}), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { container: true, flexDirection: "column", justifyContent: "center", alignItems: "center", flexGrow: 1, flexWrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: { mb: 4 }, children: (0, jsx_runtime_1.jsx)("img", { src: "/static/img/logo-login.png", width: 300 }) }), (0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: { mb: 2 }, children: (0, jsx_runtime_1.jsx)(material_1.TextField, { label: "Username", type: "text", sx: { width: 400, bgcolor: "white" }, autoFocus: true, onChange: (event) => {
                                setUsername(event.target.value);
                            } }) }), (0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: { mb: 4 }, children: (0, jsx_runtime_1.jsx)(material_1.TextField, { label: "Password", type: "password", sx: { width: 400, bgcolor: "white" }, onKeyUp: (event) => {
                                if (event.key === "Enter") {
                                    login();
                                }
                            }, onChange: (event) => {
                                setPassword(event.target.value);
                            } }) }), (0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: { mb: 2 }, children: (0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", sx: { width: 400 }, disabled: username == "" || password == "", onClick: login, children: "LOGIN" }) }), (0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: { mb: 2 }, children: (0, jsx_runtime_1.jsx)(material_1.Button, { variant: "outlined", sx: { width: 400 }, startIcon: (0, jsx_runtime_1.jsx)("img", { src: "/static/img/google.png", style: { width: 20, height: 20 } }), href: "/login/google/", children: "LOGIN WITH GOOGLE" }) }), (0, jsx_runtime_1.jsx)(material_1.Grid2, { children: (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: "caption", sx: { color: "#888888" }, children: ["\u00A9 ", year, " Vimza LLC"] }) })] })] }));
}
const root = (0, client_1.createRoot)(document.getElementById("root"));
root.render((0, jsx_runtime_1.jsx)(App, {}));
